<template>
    <div class="text-center">
        <v-dialog v-model="dialog" persistent :width="setWidth">

            <v-overlay :absolute="true" :value="loadingModal">
                <v-progress-circular :size="40" color="amber" indeterminate></v-progress-circular>
            </v-overlay>

            <v-card class="section text-section" style="background-position: right top; background-size: 30% auto; background-image: url('/img/logo_half2.png')">
                 <!-- <img class="text-section__bg" src="/img/logo_half2.png" style="opacity: 1;" alt="img" /> -->
                <v-card-title class="zn-title-dialog">
                    {{title}}
                    <v-btn v-if="!btnClose" @click="cancel" icon large color="primary" style="position: absolute;right: 14px;">
                        <v-icon style="font-size: 25px !important;color: #4caf50;">mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <slot name="content"></slot>
                    
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <slot name="action"></slot>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
   
<script>
export default {
    name: "ModalDialog",
    props: ['btnClose'],
    data() {
      return {
        loadingModal:false,
        // btnClose:true,
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        getData: null,
        setWidth:700,
        title: null,
         options: {
          color: "primary",
          zIndex: 200,
          noconfirm: false,
        },
      };
    },

    methods: {
      open(title, width) {
        this.dialog = true;
        this.title = title;
        this.setWidth = width;
        // this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
        close() {
            this.dialog = false;
        },
        loading() {
            this.loadingModal = true;
        },
        loadingEnd() {
            this.loadingModal = false;
        },
    },
  };

</script>